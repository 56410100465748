.Accordion{
  margin-bottom: 2em;
  font-size: 1.2rem;
  box-shadow: 0px 0px 5px #c9c9c9 ;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.AccordionPara{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight:500;
  font-size: 120%;
  margin: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.Toggle {
  font-size: 2rem;
  line-height: 0.7 ;
  /* line-height:1; */
  font-weight: 300;
  color: #8d8686;
}
.Answer{
  line-height: 1.4;
  font-weight: 500;
  font-size: 80%;
  width: 100%;
  border: 0;
  color: #8d8686;
  word-spacing: 0.1em;
  padding-top: 0.3rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .Accordion {
    margin-bottom: 1em;
  }
  .AccordionPara {
    font-size: 70%;
  }
  .Answer {
    font-size: 65%;
  }
}