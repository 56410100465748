.AccordionGenerator {
  width: 60%;
  margin: auto;
  margin-top: 4rem;
}

.Title {
  margin: 0.2rem 5rem;
  text-align: center;
  font-size: 2.8rem;
}
.UnderBar {
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 1em;
}


.BgOrange {
  background-color: #fc4f15;
  width: 8em;
  height: 0.3rem;
}

@media screen and (max-width: 768px) {
  .AccordionGenerator {
    width: 90%;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .Title {
    margin: 3rem 0 0.5rem 0;
    font-size: 150%;
  }
  .BgOrange {
    width: 6em;
    height: 0.2rem;
  }
}