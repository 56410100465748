.work-item {
    margin-top: 3rem;
    margin-bottom: 10rem;
}

.work-item-inner {
    display: flex;

}

.work-item-inner .bar {
    width: 5rem;
    height: 0.3rem;
    background-color: #000000;
}

.work-item-inner div {
    width: 50%;
}

.work-item-inner img {
    width: 100%;
    height: auto;
}

.work-item-inner .title {
    padding-top: 0.3rem;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.work-item-right .title {
    width: 100%;
}

.work-item-left .title {
    width: 100%;
}

.work-item-inner .description {
    width: 75%;
}

.mb {
    display: none;
}

@media screen and (max-width: 768px) {
    .lg {
        display: none;
    }
    .mb {
        display: block;
    }
    .work-item {
        margin-bottom: 5rem;
    }
    .work-item-inner {
        flex-direction: column;
    }
    .work-item-inner div {
        width: 100%;
    }
    .work-item-inner .bar {
        height: 0.2rem;
    }
    .work-item-inner .title {
        font-size: 1.7rem;
    }
    .work-item-right .title {
        width: 100%;
        margin-bottom: 1rem;
    }
    .work-item-left .title {
        width: 100%;
    }
    
    .work-item-inner .description {
        width: 100%;
        margin-bottom: 3rem;
    }
}