.services-wrapper {
    width: 100%;
}

.services-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-inner .header p {
    font-size: 3rem;
    font-weight: 300;
    text-align: center;
}

.services-inner .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 0;
    width: 90%;
}

.card-wrapper {
    position: relative;
    width: 35.8%;
    height: 20.5rem;
    margin-top: 4rem;
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.card-wrapper.branding-and-packaging {
    background-image: url(../images/branding_and_packaging_card.jpg);
}

.card-wrapper.content-marketing {
    background-image: url(../images/content_marketing_card.jpg);
}

.card-wrapper.email-marketing {
    background-image: url(../images/email_marketing_card.jpg);
}

.card-wrapper.fb-and-insta-ads {
    background-image: url(../images/fb_and_insta_ads_card.jpg);
}

.card-wrapper.mobile-dev {
    background-image: url(../images/mobile_dev_card.jpg);
}

.card-wrapper.seo {
    background-image: url(../images/seo_card.jpg);
}

.card-wrapper.social-media-marketing {
    background-image: url(../images/social_media_marketing_card.jpg);
}

.card-wrapper.web-dev {
    background-image: url(../images/web_dev_card.jpg);
}

.card-img-wrapper img {
    width: 100%;
    height: auto;
}

.card-content-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 1.5rem 1rem;
    opacity: 0.7;
    z-index: 5;
    transition: all ease 250ms;
}

.card-content-placeholder:hover {
    opacity: 0;
}

.card-content-placeholder .card-title {
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
    color: #656565;
    padding: 1rem;
    margin-bottom: 0 !important;
    background-color: rgba(255, 255, 255, 0.7);
    /* border-radius: 1rem; */
}

.card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 1.5rem 1rem;
    opacity: 0;
    z-index: 6;
    transition: all ease 250ms;
}

.card-content.show {
    opacity: 1;
    background-image: url(../images/card_shade.png);
}

.card-title {
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.card-description {
    color: #ffffff;
}

.services-inner .card-footer {
    border: none;
    padding: 0;
    background-color: inherit;
    border-radius: 1rem;
    width: 80%;
    margin: auto;
    padding-bottom: 1rem;
}

.services-inner .card-link {
    display: flex;
    justify-content: center;
}

.services-inner .card-link a {
    color: #fc4f15;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
}

.services-inner .card-link a:hover {
    color: #fc4f15;
}

.services-wrapper .footer button {
    border: none;
    background-color: transparent;
}

.services-wrapper .footer button:focus {
    outline: none;
}

.services-wrapper .footer button p {
    margin: 0;
    color: #fc4f15;
    font-size: 1.5rem;
}

.services-wrapper .footer img {
    height: 1.1rem;
    width: auto;
}

@media screen and (max-width: 768px) {
    .services-inner .header p {
        font-size: 1.5rem;
    }
    .card-wrapper {
        width: 100%;
        margin-top: 3rem;
    }
}

@media screen and (min-width: 1900px) {
    .services-inner .header p {
        font-size: 5rem;
    }
    .card-wrapper {
        height: 40rem;
        margin-top: 7rem;
        margin-bottom: 1rem;
    }
    .card-title {
        font-size: 3.5rem;
    }
    .card-description {
        font-size: 2rem;
    }
    .services-inner .card-footer {
        padding-bottom: 2rem;
    }
    .services-inner .card-link a {
        color: #fc4f15;
        font-size: 2rem;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
    }
    /* .services-inner .card-footer a, .services-wrapper .footer button p {
        font-size: 2rem;
    } */
}