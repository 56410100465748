
.caption-wrapper {
    width: 100%;
    margin-top: 0;
    padding: 3rem 0;
    background-size: cover;
}

.caption-wrapper.main-caption {
    background-image: url(../images/landing_background.jpg);
}

.caption-wrapper.web-dev {
    background-image: url(../images/webdev_page_background.jpg);
}

.caption-inner {
    display: flex;
    justify-content: space-between;
}

.caption-content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 33rem;
    z-index: 5;
}

.caption-content-left h2 {
    font-size: 4rem;
    line-height: 4.2rem;
    color: #ffffff;
}

.caption-content-left p {
    color: #ffffff;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 300;
    margin-top: 1rem;
}

.caption-content-left button {
    width: 50%;
    border: none;
    box-shadow: none;
    color: #ffffff;
    background-color: #fc4f15;
    padding: 0.5rem;
    font-size: 1.5rem;
    border-radius: 5px;
    margin-top: 1rem;
}

.caption-content-left button:focus, .caption-content-left button:hover {
    outline: none;
    filter: contrast(1.2);
}

.caption-content-right {
    width: 40%;
    display: flex;
    align-items: center;
}

.caption-content-right img {
    height: 33rem;
    width: auto;
    transform: translate(-6rem, 0.8rem);
}

.webdev-form {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 1.5rem 2rem;
    padding-top: 3rem;
}

.webdev-form .form-group input, .webdev-form .form-group textarea {
    width: 100%;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
    padding: 0.5rem;
}

@media screen and (max-width: 767.89px) {
    .lg {
        display: none;
    }
    .caption-wrapper {
        margin-top: 0;
        padding: 4rem 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .caption-inner {
        flex-direction: column;
        align-items: center;
    }
    .caption-content-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .caption-content-left h2 {
        font-size: 2rem;
        line-height: 2.3rem;
        margin-top: 0;
        text-align: center;
    }
    .caption-content-left p {
        font-size: 0.8rem;
        line-height: 1rem;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }
    .caption-content-left button {
        width: auto;
        padding: 0.4rem;
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }
    .caption-content-right img {
        height: auto;
        width: 100%;
        margin-top: 2rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023.89px) {
    .lg {
        display: none;
    }
    .caption-wrapper {
        padding: 5rem 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .caption-inner {
        flex-direction: column;
        align-items: center;
    }
    .caption-content-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .caption-content-left h2 {
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-top: 0;
        text-align: center;
    }
    .caption-content-left p {
        font-size: 1rem;
        line-height: 1.2rem;
        margin-top: 0;
        margin-bottom: 1rem;
        text-align: center;
    }
    .caption-content-left button {
        width: auto;
        padding: 0.5rem;
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }
    .caption-content-right img {
        height: auto;
        width: 100%;
        margin-top: 2rem;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
    .caption-wrapper {
        padding: 5rem 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .caption-content-left h2 {
        margin-top: 0;
        font-size: 3.5rem;
        line-height: 3.5rem;
    }
    .caption-content-right img {
        height: 25rem;
        transform: translate(-3rem, 0.8rem);
    }
    .caption-content-left {
        padding-top: 6rem;
        padding-bottom: 3.2rem;
        height: auto;
    }
    .caption-content-left p {
        margin-top: 1rem;
        font-size: 1.5rem;
    }
    .caption-content-left button {
        padding: 0.5rem 1rem;
        font-size: 1.7rem;
        margin-top: 1rem;
        width: auto;
    }
}

@media screen and (min-width: 1900px) {
    .caption-wrapper {
        padding-top: 10rem;
        padding-bottom: 4rem;
    }
    .caption-content-left h2 {
        font-size: 5rem;
        line-height: 6rem;
    }
    .caption-content-left p {
        font-size: 1.5rem;
    }
    .caption-content-left button {
        padding: 0.5rem 1rem;
        font-size: 2.7rem;
        margin-top: 3rem;
        border-radius: 10px;
        width: auto;
    }
    .caption-content-right img {
        height: 43rem;
        transform: translate(-6rem, -5rem);
    }
}

@media screen and (min-width: 2500px) {
    .caption-wrapper {
        padding-top: 20rem;
        padding-bottom: 15rem;
    }
    .caption-content-left h2 {
        font-size: 8rem;
        line-height: 8rem;
    }
    .caption-content-left button {
        padding: 1rem 1.5rem;
        font-size: 3.5rem;
        margin-top: 3rem;
        border-radius: 10px;
        width: auto;
    }
    .caption-content-left p {
        font-size: 3rem;
        line-height: 3.7rem;
    }
    .caption-content-right img {
        height: 55rem;
        transform: translate(-9rem, -11rem);
    }
}