.sub-caption-wrapper {
    width: 100%;
    background-color: #fc4f15;
    margin-top: 3rem;
}

.sub-caption-inner {
    display: flex;
    justify-content: space-between;
}

.sub-caption-inner div {
    width: 50%;
    overflow: hidden;
}

.sub-caption-inner div img {
    height: 34rem;
    z-index: 1;
}

.sub-caption-left {
    padding-bottom: 2rem;
    z-index: 3;
}

.sub-caption-inner .title {
    width: fit-content;
    margin-top: 4rem;
}

.sub-caption-inner .title h2 {
    font-size: 3rem;
    color: #ffffff;
}

.sub-caption-inner .description {
    font-size: 1.2rem;
    color: #ffffff;
    width: 80%;
    margin-top: 1.5rem;
}

.sub-caption-inner button {
    width: auto;
    border: none;
    box-shadow: none;
    color: #fc4f15;
    background-color: #ffffff;
    padding: 0.5rem 7rem;
    font-size: 1.3rem;
    border-radius: 5px;
    margin-top: 1rem;
}

.sub-caption-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.sub-caption-left img {
    position: absolute;
    transform: translate(-9rem, -1.5rem);
}

.sub-caption-right .title {
    margin-top: 5rem;
}

.sub-caption-right .description {
    line-height: 2rem;
    text-align: center;
}

.sub-caption-right button {
    margin-top: 3.35rem;
    margin-bottom: 2rem;
}

.sub-caption-right img {
    position: absolute;
    transform: translate(5rem, -2.7rem);
}


@media screen and (max-width: 768px) {
    .lg {
        display: none;
    }
    .sub-caption-wrapper {
        margin-top: 0;
    }
    .sub-caption-inner {
        flex-direction: column;
    }
    .sub-caption-inner div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .sub-caption-inner .title {
        width: 100%;
        margin-top: 2rem;
    }
    .sub-caption-inner .title h2 {
        font-size: 1.5rem;
        text-align: center;
    }
    .sub-caption-inner .description {
        width: 80%;
        margin: auto;
        margin-top: 1rem;
        font-size: 1rem;
        text-align: center;
    }
    .sub-caption-inner img {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
    .sub-caption-inner div img {
        height: 28.75rem;
    }
    .sub-caption-left img {
        transform: translate(-10rem, 5.75rem);
    }
    .sub-caption-right img {
        transform: translate(3rem, 2.55rem);
    }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .sub-caption-inner div img {
        height: 33.7rem;
    }
}


@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .sub-caption-right img {
        transform: translate(8rem, -4.42rem);
    }
}