.thankyou-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.thankyou-inner h1 {
    color: #000000;
    margin-bottom: 2rem;
    text-align: center;
}

.thankyou-inner p {
    color: #8d8d8d;
    font-weight: 300;
    text-align: center;
}

.thankyou-inner a {
    display: block;
    color: #fc4f15;
    text-align: center;
    margin-top: 4rem;
}

@media screen and (max-width: 768px) {
    .thankyou-inner h1 {
        font-size: 2rem;
    }
    .thankyou-inner p {
        font-size: 0.8rem;
    }
}