
.carousel-wrapper {
  margin: 7rem 0;
}

.carousel-title {
  width: fit-content;
  margin: auto;
}

.carousel-title h3 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: center;
}

.indicator {
  background-color: inherit !important;
  height: 10px !important;
  width: 10px !important;
  border: 3px solid #fc4f15!important;
  border-radius: 100% !important;
}

.indicators > .active {
  background-color: #fc4f15 !important;
}

.indicators {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding-left: 32%;
  padding-right: 32%;
  left: 0;
  z-index: 15;
  margin-top: 1.5rem;
  /* margin-right: 15%;
  margin-left: 15%; */
  list-style: none;
}

.slide-img {
    width: 100%;
    height: auto;
  }

@media (max-width: 767.89px) {
  .carousel-wrapper {
    margin: 4rem 0;
  }
  .indicators {
    padding-left: 25%;
    padding-right: 25%;
  }
  .carousel-title h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 360px) {
  .indicators {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media screen and (min-width: 1900px) {
  .carousel-wrapper {
    padding: 7rem 0;
  }
  .carousel-title h3 {
    font-size: 5rem;
  }
  .indicator {
    height: 2rem !important;
    width: 2rem !important;
  }
}

