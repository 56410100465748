.under-bar {
    display: flex;
    justify-content: center;
    width: 100% !important;
}

.under-bar-inner {
    width: 40%;
    height: 0.3rem;
}

.under-bar-inner.bg-light {
    background-color: #ffffff;
}

.under-bar-inner.bg-dark {
    background-color: #000000;
}

.under-bar-inner.bg-orange {
    background-color: #fc4f15;
}

@media screen and (max-width: 768px) {
    .under-bar-inner.bg-light {
        width: 20%;
    }
    .under-bar-inner {
        height: 0.2rem;
    }
}