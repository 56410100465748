.testimonials-wrapper {
    width: 100%;
    padding: 5rem 0;
}

.testimonials-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonials-header {
    width: fit-content;
}

.testimonials-header .title {
    text-align: center;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 0;
}

.testimonials-header .subtitle {
    color: #7E7575;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 0.5rem;
}

.testimonials-header .under-bar-inner {
    width: 40%;
}

.testimonials {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.testimonial-card {
    position: relative;
    background-color: #ffffff;
    padding: 5rem 2.5rem;
    width: 40%;
    box-shadow: 0px 2px 10px #aaaaaa;
    border-radius: 0.5rem;
}

.avatar-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -3.5rem;
    right: 38%;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: 2px solid #fc4f15;
    /* border: 2px solid #f9f9f9; */
    background-color: #ffffff;
    overflow: hidden;
}

.avatar-wrapper img {
    width: 80%;
    height: auto;
}

.testimonial-card .title {
    font-size: 1.7rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0;
}

.testimonial-card .subtitle, .testimonial-card .description {
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
    color: #7E7575;
}

.testimonial-card .description {
    margin-top: 5rem;
}

@media screen and (max-width: 767.89px) {
    .lg {
        display: none;
    }
    .testimonials-wrapper {
        padding: 2rem 0;
        background-color: #f5f5f5;
        padding-bottom: 0;
    }
    .testimonials-header .title {
        font-size: 1.5rem;
        margin-bottom: 0;
        line-height: 2rem;
    }
    .testimonials-header .subtitle {
        font-size: 1rem;
    }
    .testimonials-header .under-bar-inner {
        width: 25%;
    }
    .testimonial-card {
        width: 100%;
        margin-bottom: 7rem;
    }
    .avatar-wrapper {
        right: 33%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023.89px) {
    .lg {
        display: none;
    }
    .testimonials-wrapper {
        padding: 2rem 0;
    }
    .testimonials-header .title {
        font-size: 1.5rem;
        margin-bottom: 0;
    }
    .testimonials-header .subtitle {
        font-size: 1rem;
    }
    .testimonial-card {
        width: 100%;
        margin-bottom: 7rem;
    }
    .avatar-wrapper {
        right: 42%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
    .avatar-wrapper {
        right: 35%;
    }
}

@media screen and (min-width: 1900px) {
    .avatar-wrapper {
        right: 44%;
    }
}