button:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:focus, textarea:focus {
  outline-color: #fc4f15 !important;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

@media screen and (min-width: 1900px) {
  .container {
    max-width: 1800px;
  }
}

@media screen and (min-width: 2500px) {
  .container {
    max-width: 2300px;
  }
}