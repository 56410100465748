.header-wrapper {
  background-color: #ffffff;
  padding: 1.5rem 0;
  box-shadow: 0px 1px 5px #c9c9c9;
}

.logo-header {
    height: 2rem;
    width: auto;
  }
  
  .header-button {
    margin-left: 25vw;
    position: relative;
    bottom: 20%;
  }
  
  .nav-wrapper {
    margin-top: 15px;
  }
  
  .btn {
    background-color: #FC4F15;
    color: #ffffff;
    font-weight: 700;
  }
  
  .navbar-light .navbar-toggler-icon {
    height: 23.89px;
    width: 23.46px;
    /* background-image: url(../assets/images/Menu.png); */
  }
  
  .navbar-light .navbar-toggler {
    border-color: transparent;
  }
  
  .btn:focus {
    outline: transparent;
  }

  .btn:hover {
    color: #ffffff;
  }
  
  .tb {
    display: none;
  }
  
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
  
  .dropdown-menu {
    border-radius: 10px;
    font-family: inherit;
  }
  
  .dropdown-item:hover {
    background-color: #FC4F15;
    color: white;
  }
  
  .nav-item a {
    color: #000000;
    margin-right: 2rem;
    font-weight: 700;
  }
  
  a:hover {
    text-decoration: none;
    color: #FC4F15
  }
  
  .nav-link {
    font-family: inherit;
    font-weight: bold;
  }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url(../images/menu_icon.png);
  }

  .chevron {
      height: 0.5rem;
      width: auto;
      margin-left: 0.2rem;
  }

  .get-started-lg {
      margin-left: 3rem;
  }

  .get-started-mb {
    display: none;
  }
  
  @media (max-width: 767.89px) {
    .header-wrapper {
      padding: 0.5rem 0;
    }
    .logo-header {
      height: 1.5rem;
      width: auto;
      margin-left: 1rem;
    }
    .navbar {
      justify-content: flex-start;
    }
    .navbar-nav {
      margin-top: 1rem;
    }
    .nav-item {
      margin-bottom: 0.5rem;
      margin-left: 10px;
    }
    .get-started-lg {
      display: none;
    }
    .get-started-mb {
      display: block;
      margin-top: 1rem;
    }
    .tb {
      display: none;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .logo-header {
      height: 35px;
      width: 143px;
    }
    .get-started-lg {
      display: none;
    }
    .navbar {
      justify-content: flex-start;
    }
    .tb {
      display: block;
    }
    .hamburger {
      display: block;
    }
  }
  
  .nav-link {
    padding: 0;
    margin: 0;
    color: #FC4F15;
  }
  
  .nav-text {
    color: #FC4F15;
  }
  