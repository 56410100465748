.mb {
  display: none;
}

.tb {
  display: none;
}

.footer-content-wrapper {
  background-color: #f5f5f5;
  padding: 96px;
  height: 500px;
}

.footer-icon {
  height: 36.87px;
  width: 36.87px;
  margin: 5px 5px;
}

.logo-footer {
  width: 20rem;
  height: auto;
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.ibadan {
  height: 19px;
  width: fit-content;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}

.address {
  width: fit-content;
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}

.form-inline {
  flex-flow: row nowrap;
}

.arrow-sub {
  height: auto;
  width: 37px;
  transform: scaleY(-1);
}

.input-sub, .form-inline .form-control {
  height: 37.28px;
  width: 386.67px;
}

.btn-sub {
  width: 57.69px;
  height: 37.28px;
  background-color: #39f;
  padding: 0px;
}

.sub-txt {
  height: 21.29px;
  color: #333;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  margin: 0px;
}

.footer-bottom {
  align-items: center;
  margin-top: 100px;
  padding-bottom: 70px;
}

.sub {
  padding-left: 96px;
}

.copyright-text-wrapper {
  width: 100%;
  margin-top: 1rem;
}

.copyright-text {
  color: #656161;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

.col-space {
  display: none;
}

.image-group {
  width: 30%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.image-group-mb {
  display: none;
}

.copy-mb {
  display: none;
}

.footer-content-wrapper ::placeholder {
  font-size: 14px;
  color: #4f4f4f;
  text-transform: lowercase;
}

.contact-details {
  margin-top: 3rem;
}

.contact-details a {
  color: #656161;
  font-size: 0.7rem;
}

.contact-details img {
  margin-right: 0.5rem;
  height: 1rem;
  width: auto;
}

@media (max-width: 767.89px) {
  .main {
    display: none;
  }
  .mb {
    display: block;
  }
  .logo-footer {
    height: auto;
    width: 82.21px;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .case-studies-footer {
    margin-top: -5.3rem;
  }
  .ibadan {
    height: 10px;
    width: fit-content;
    color: #333;
    font-size: 8px;
    font-weight: 600;
    line-height: 8px;
  }
  .address {
    width: fit-content;
    color: #4f4f4f;
    font-size: 8px;
    font-weight: 300;
    line-height: 16px;
  }
  .footer-content-wrapper {
    border-top: 0.5px solid #f4f4f4;
    padding: 35px;
  }
  .col-space {
    display: block;
  }
  .image-group-mb {
    display: flex;
  }
  .footer-icon {
    height: 16.52px;
    width: 16.39px;
  }
  .copy {
    display: none;
  }
  .copy-mb {
    display: block;
  }
  /* .sub {
    padding-left: 0px;
  } */
  .form-inline {
    flex-flow: row nowrap;
  }
  .sub-txt {
    height: 12px;
    color: #333;
    font-size: 9px;
    font-weight: 300;
    line-height: 12px;
  }
  .input-sub,
  .form-inline .form-control {
    height: 30.47px;
    width: 164.47px;
    border-radius: 0px;
  }
  .btn-sub {
    height: 30.47px;
    width: 39.47px;
    border-radius: 0px;
  }
  .arrow-sub {
    height: auto;
    width: 100%;
  }
  .copyright-text {
    font-size: 8px;
  }

  .contact-details a {
    font-size: 0.3rem;
  }

  .contact-details p {
    margin-bottom: 0;
  }
  
  .contact-details img {
    margin-right: 0.2rem;
    height: 0.5rem;
    width: auto;
  }
}

@media (max-width: 360px) {
  .footer-content-wrapper {
    padding: 10px;
    padding-top: 50px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main {
    display: none;
  }
  .mb {
    display: none;
  }
  .logo-footer {
    height: 31.03px;
    width: 107.21px;
    margin-top: -0.5rem;
    margin-bottom: 30px;
  }
  .ibadan {
    width: fit-content;
    color: #333;
    font-size: 8px;
    font-weight: 600;
    line-height: 8px;
  }
  .address {
    width: fit-content;
    color: #4f4f4f;
    font-size: 8px;
    font-weight: 300;
    line-height: 16px;
  }
  .footer-content-wrapper {
    padding: 3rem;
  }
  .col-space {
    display: none;
  }
  .image-group {
    display: none;
  }
  .image-group-mb {
    display: block;
  }
  .footer-icon {
    height: 16.52px;
    width: 16.39px;
  }
  .copy {
    display: none;
  }
  .copy-mb {
    display: block;
  }
  .sub {
    padding-left: 260px;
  }
  .form-inline {
    flex-flow: row nowrap;
  }
  .sub-txt {
    height: 22px;
    color: #333;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
  }
  .input-sub,
  .form-inline .form-control {
    height: 39.47px;
    width: 304.47px;
    border-radius: 0px;
  }
  .btn-sub {
    height: 39.47px;
    border-radius: 0px;
  }
  .arrow-sub {
    height: 38.47px;
    width: 41.47px;
    margin-left: -17px;
  }
  .copyright-text-wrapper {
    width: 100%;
  }
  .copyright-text {
    font-size: 0.5rem;
  }
}
