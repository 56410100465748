.works-caption {
    width: 100%;
    margin-top: 0;
    padding: 10rem 0;
    background-image: url(../images/works_background.jpg);
    margin-bottom: 5rem;
}

.works-caption .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.works-caption .title {
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.5rem;
    color: #ffffff;
}

.works-caption .subtitle {
    text-align: center;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 300;
    color: #ffffff;
}

.works-caption button {
    border: none;
    box-shadow: none;
    color: #ffffff;
    background-color: #fc4f15;
    padding: 0.5rem;
    font-size: 1.5rem;
    border-radius: 5px;
    margin-top: 1rem;
}

.works-caption button:focus, .works-caption button:hover {
    outline: none;
    filter: contrast(1.2);
}

.about-caption {
    width: 100%;
    margin-top: 0;
    padding-top: 10rem;
    padding-bottom: 15rem;
    background-image: url(../images/about_background.jpg);
    background-size: cover;
}

.about-caption-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-caption-inner button {
    margin-top: 1rem;
}

.about-caption .title {
    text-align: center;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 4.5rem;
    color: #ffffff;
}

.about-caption .subtitle {
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    color: #ffffff;
}

.services-caption {
    width: 100%;
    margin-top: 0;
    padding-bottom: 3rem;
}

.services-caption-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7%;
    padding-bottom: 14%;
    background-size: cover;
    background-position: center;
}

.services-caption-inner.branding-and-packaging {
    background-image: url(../images/branding_and_packaging_background.jpg);
}

.services-caption-inner.content-marketing {
    background-image: url(../images/content_marketing_background.jpg);
}

.services-caption-inner.email-marketing {
    background-image: url(../images/email_marketing_background.jpg);
}

.services-caption-inner.fb-and-insta-ads {
    background-image: url(../images/fb_and_insta_ads_background.jpg);
}

.services-caption-inner.mobile-dev {
    background-image: url(../images/mobile_dev_background.jpg);
}

.services-caption-inner.seo {
    background-image: url(../images/seo_background.jpg);
}

.services-caption-inner.social-media-marketing {
    background-image: url(../images/social_media_marketing_background.jpg);
}

.services-caption-inner.web-dev {
    background-image: url(../images/web_dev_background.jpg);
}

.services-caption-inner .title {
    color: #ffffff;
    padding-left: 1rem;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: 500;
    width: 70%;
    border-left: 0.3rem solid #ffffff;
}

.services-caption-inner p {
    margin-top: 1rem;
    width: 65%;
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 300;
}

.services-caption-inner .button-wrapper {
    width: 65%;
}

.services-caption-inner button {
    margin-top: 2rem;
    font-size: 2rem;
}

@media screen and (max-width: 768px) {
    .works-caption, .about-caption {
        margin-top: 0;
        padding: 3rem 0;
    }
    .works-caption .title, .about-caption .title, .services-caption .title {
        font-size: 170%;
        line-height: 2rem;
    }
    .works-caption .subtitle {
        font-size: 70%;
        line-height: 0.9rem;
    }
    .about-caption .subtitle {
        width: 70%;
        margin: auto;
        font-size: 0.6rem;
        line-height: 0.8rem;
    }
    .works-caption button {
        padding: 0.5rem;
        font-size: 1rem;
    }
    .services-caption {
        padding-top: 0;
        padding-bottom: 3rem;
        margin-top: 0;
    }
    .services-caption-inner {
        padding-top: 3rem;
        padding-bottom: 6rem;
    }
    .services-caption-inner .title {
        width: 85%;
        padding-left: 0.6rem;
    }
    .services-caption-inner p {
        width: 75%;
        font-size: 100%;
    }
    .services-caption-inner .button-wrapper {
        width: 75%;
    }
    .services-caption-inner button {
        margin-top: 1rem;
        font-size: 1rem;
    }
}
