.mission-wrapper {
    width: 100%;
    padding: 7rem 0;
}

.mission-inner {
    display: flex;
}

.mission-inner .bar {
    width: 5rem;
    height: 0.3rem;
    background-color: #000000;
}

.mission-inner div {
    width: 50%;
}

.mission-left .title {
    width: 40%;
    padding-top: 0.7rem;
    font-size: 2.5rem;
    margin-bottom: 3rem;
}

.mission-left .description {
    width: 70%;
}

.mission-right img {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .mission-wrapper {
        padding: 4rem 0;
    }
    .mission-right {
        display: none;
    }
    .mission-inner div {
        width: 100%;
    }
    
    .mission-left .title {
        width: 100%;
        padding-top: 0.7rem;
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
    
    .mission-left .description {
        width: 100%;
    }
}