.service-features-inner .bar {
    width: 5rem;
    height: 0.3rem;
    background-color: #000000;
}

.service-features-wrapper .title {
    width: fit-content;
    text-align: center;
    margin: auto;
}

.service-features-wrapper .title h3 {
    font-size: 250%;
}

.service-features-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5rem;
}

.feature-item-wrapper {
    width: 30%;
}

.feature-item-lg {
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    margin-top: 5rem;
}

.feature-content-img {
    width: 25%;
}

.feature-content-img img {
    width: 80%;
    height: auto;
    margin-bottom: 2rem;
}

.feature-content-text {
    width: 80%;
}

.feature-content-text h3 {
    padding-top: 0.7rem;
    font-size: 170%;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

/* .feature-content-text p {
    width: 95%;
} */

.feature-item-mb {
    display: none;
}

.mb {
    display: none;
}

.features-slide-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12% 15%;
    background-size: cover;
    background-position: center;
    /* background-attachment: fixed; */
}

.features-slide-flex.slide-flex-one {
    background-image: url(../images/flex-one.jpg);
}

.features-slide-flex.slide-flex-two {
    background-image: url(../images/flex_two.jpg);
}

.features-slide-flex p {
    color: #ffffff;
    text-align: center;
    width: 80%;
}

.features-slide-flex button {
    color: #ffffff;
    font-size: 120%;
    padding: 0.7rem 1rem;
    background-color: #fc4f15;
    border: none;
    border-radius: 5px;
}

.features-slide-quote {
    display: flex;
    justify-content: space-between;
    padding: 10% 0;
}

.features-slide-quote div {
    width: 47%;
}

.features-slide-quote img {
    width: 90%;
    height: auto;
}

.features-slide-quote h3 {
    width: 80%;
    line-height: 130%;
    margin-bottom: 2rem;
}

.features-slide-quote p {
    width: 90%;
    line-height: 300%;
}

@media screen and (max-width: 768px) {
    .lg {
        display: none;
    }
    .mb {
        display: block;
    }
    .service-features-wrapper .title h3 {
        font-size: 1.7rem;
    }
    .feature-item-lg {
        display: none;
    }
    .service-features-inner {
        flex-direction: column;
    }
    .feature-item-wrapper {
        width: 100%;
    }
    .feature-item-mb {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 1.5rem;
    }
    .feature-content-img img {
        width: 100%;
        margin-bottom: 1rem;
    }
    .feature-content-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .feature-content-text h3 {
        width: 100%;
        padding-top: 0.7rem;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        text-align: center;
    }
    .feature-content-text p {
        width: 100%;
        margin-top: 2rem;
        text-align: center;
    }
    .features-slide-flex {
        padding: 12% 0;
    }
    .features-slide-quote {
        flex-direction: column;
        align-items: center;
    }
    .features-slide-quote div {
        width: 90%;
    }
    .features-slide-quote img {
        display: none;
    }
    .features-slide-quote h3 {
        width: 90%;
        font-size: 1.5rem;
    }
    .features-slide-quote p {
        width: 100%;
        line-height: 250%;
    }
}